import UploadInput from "@cospex/client/converter/components/UploadInput";
import UploadsUI from "@cospex/client/converter/components/UploadsUI";
import { OPERATION } from "@cospex/client/converter/constants";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import React, { useEffect } from "react";

const handleDownload = async (url: string, filename: string) => {
  const response = await axios.get(url, { responseType: "blob" });
  fileDownload(response.data, filename);
};

const prettyDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleDateString("en-US") + " " + d.toLocaleTimeString("en-US");
};

interface IDashboardFiles {
  type: ResponseFile["operation"]["type"];
}

export default function DashboardFiles({ type }: IDashboardFiles) {
  const { t } = useTranslation();
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { uploads, removeAllUploads } = useUploads();
  const [initiatingConversion, setInitiatingConversion] = React.useState(false);

  const query = useQuery(["files"], () =>
    axios.get("/api/converter/files").then((res) => res.data)
  );

  const { data, isLoading, error } = query;

  useEffect(() => {
    const x = setInterval(() => {
      query.refetch();
    }, 10_000);
    return () => clearInterval(x);
  });

  if (isLoading)
    return (
      <>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
      </>
    );

  const files = (data as ResponseFile[]).filter(
    (file) => file.operation.type === type
  );

  if (error) return "An error has occurred: " + (error as any).message;
  return (
    <>
      <Card
        elevation={14}
        sx={{
          borderRadius: 2,
          p: 4,
          mb: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          {type === OPERATION.CONVERSION && t("dashboard-new-conversion")}
          {type === OPERATION.COMPRESSION && t("dashboard-new-optimization")}
        </Typography>
        <UploadInput
          type={type}
          compressionStyle={
            type === OPERATION.COMPRESSION ? { target: "" } : undefined
          }
        />
        <UploadsUI />
        <LoadingButton
          variant="contained"
          size="large"
          disableElevation
          type="submit"
          disabled={
            !uploads.length || !uploads.some((u) => u.realProgress >= 100)
          }
          fullWidth
          loading={initiatingConversion}
          onClick={async () => {
            setInitiatingConversion(true);
            await Promise.all(uploads.map((u) => u.onComplete?.()));
            await query.refetch();
            setInitiatingConversion(false);
            removeAllUploads();
          }}
        >
          {type === OPERATION.CONVERSION && t("uploader-button")}
          {type === OPERATION.COMPRESSION && t("uploader-button-compress")}
        </LoadingButton>
      </Card>

      <Card
        elevation={14}
        sx={{
          borderRadius: 2,
          p: {
            xs: 0,
            md: 4,
          },
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          sx={{ px: { xs: 4, md: 0 }, pt: { xs: 4, md: 0 } }}
        >
          {t("dashboard-overview-title")}
        </Typography>
        {md ? (
          <TableContainer>
            <Table aria-label="downloads table">
              <TableHead
                sx={{ "@media (max-width: 768px)": { display: "none" } }}
              >
                <TableRow>
                  <TableCell align="left">
                    {t("dashboard-overview-date-time")}
                  </TableCell>
                  <TableCell align="center">
                    {t("dashboard-overview-name")}
                  </TableCell>
                  <TableCell align="center">
                    {t("dashboard-overview-status")}
                  </TableCell>
                  {type === OPERATION.CONVERSION && (
                    <TableCell align="center">
                      {t("dashboard-overview-output-format")}
                    </TableCell>
                  )}
                  <TableCell align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow
                    key={file.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "@media (max-width: 768px)": {
                        display: "block",
                        marginBottom: "0.625rem",
                      },
                    }}
                  >
                    <TableCell align="left">
                      {prettyDate(file.createdAt)}
                    </TableCell>

                    <TableCell align="center">{file.name}</TableCell>

                    <TableCell align="center">
                      <Chip
                        label={t(file.status)}
                        variant="outlined"
                        color={(() => {
                          switch (file.status) {
                            case "Done":
                              return "success";
                            case "Pending":
                              return "warning";
                            default:
                              return "error";
                          }
                        })()}
                      />
                    </TableCell>

                    {file.operation.type === OPERATION.CONVERSION && (
                      <TableCell align="center">
                        <Chip
                          label={file.operation.outputFormat!.toUpperCase()}
                        />
                      </TableCell>
                    )}

                    <TableCell align="center">
                      {file.status === "Done" &&
                        (md ? (
                          <IconButton
                            sx={{ fontSize: 48 }}
                            component={Download}
                            onClick={() =>
                              handleDownload(file.url, file.processedName!)
                            }
                          />
                        ) : (
                          <Button
                            startIcon={<Download />}
                            onClick={() =>
                              handleDownload(file.url, file.processedName!)
                            }
                          >
                            {t("dashboard-overview-action")}
                          </Button>
                        ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <List>
            {files.map((file) => (
              <React.Fragment key={file.id}>
                <ListItem
                  sx={{
                    borderTop: "1px solid",
                    borderColor: "divider",
                    pr: 8,
                  }}
                  secondaryAction={
                    file.status === "Done" ? (
                      <IconButton
                        sx={{ fontSize: 42 }}
                        component={Download}
                        onClick={() =>
                          handleDownload(file.url, file.processedName!)
                        }
                      ></IconButton>
                    ) : null
                  }
                >
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center" gap={1} mb={2}>
                        {file.operation.type === OPERATION.CONVERSION ? (
                          <Chip
                            label={file.operation.outputFormat!.toUpperCase()}
                            variant="outlined"
                            color="primary"
                          />
                        ) : (
                          ""
                        )}
                        <Chip
                          label={t(file.status)}
                          variant="outlined"
                          color={(() => {
                            switch (file.status) {
                              case "Done":
                                return "success";
                              case "Pending":
                                return "warning";
                              default:
                                return "error";
                            }
                          })()}
                        />
                        <Box sx={{ fontSize: 14 }}>
                          {prettyDate(file.createdAt)}
                        </Box>
                      </Box>
                    }
                    secondary={
                      <Box
                        position="relative"
                        alignItems="center"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        width="100%"
                      >
                        {file.name}
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        )}
      </Card>
    </>
  );
}
